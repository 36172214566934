<template>
    <el-dialog title="查看档案信息" :close-on-click-modal="false" :fullscreen="true" append-to-body :visible.sync="visible"
               class="collectForm dialogTable" :before-close="handleClose">
        <div class="formBox">
            <div v-loading="loading">
                <el-form size="small" :inline="true" :model="inputForm" ref="inputForm"
                         label-width="180px" disabled class="recordCollect">
                    <el-descriptions title="档案分类"></el-descriptions>
                    <el-form-item label="档案分类：">
                        <el-input v-model="archivesBasicName"></el-input>
                    </el-form-item>
                    <el-form-item label="档案类型：">
                        <el-input v-model="archivesBasicDataType"></el-input>
                    </el-form-item>
                    <el-descriptions title="档案信息"></el-descriptions>
                    <el-form-item class="rowItem" label="题名：" prop="archivesDataName">
                        <el-input
                                type="textarea"
                                :rows="1"
                                placeholder="请输入题名"
                                maxlength="100"
                                v-model.trim="inputForm.archivesDataName">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="状态：">
                        <el-select class="w100i" v-model="warehousingState">
                            <el-option
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                    v-for="item in $dictUtils.getDictList('warehousing_state')">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="全宗号：" prop="quanzongNumber">
                        <el-input placeholder="请输入全宗号（限50字）"
                                  maxlength="50"
                                  v-model.trim="inputForm.quanzongNumber">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="全宗名称：" prop="quanzongName">
                        <el-input placeholder="请输入全宗名称（限50字）"
                                  maxlength="50"
                                  v-model.trim="inputForm.quanzongName">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="档号：" prop="reference">
                        <el-input placeholder="请输入档号（限50字）"
                                  maxlength="50"
                                  v-model.trim="inputForm.reference">
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="item.forName ? item.forName : item.basicName + '：'" :prop="item.code"
                                  v-for="(item, index) in config"
                                  :key="index">
                        <!--字符-->
                        <el-input :type="item.maxLength && item.maxLength > 100 ? 'textarea': ''"
                                  v-model.trim="inputForm[item.code]"
                                  v-if="item.archivesBasicTypeCode == 'text' && !item.archivesBasicMultiselectList.length"></el-input>
                        <!--数值、浮点数-->
                        <el-input v-model.trim="inputForm[item.code]"
                                  v-if="item.archivesBasicTypeCode == 'integer' || item.archivesBasicTypeCode == 'double'"></el-input>
                        <!--下拉选择-->
                        <el-select class="w100i" v-model="inputForm[item.code]"
                                   :multiple="item.archivesBasicMultiselectList[0].selectBy == 1"
                                   v-if="item.archivesBasicMultiselectList.length">
                            <el-option v-for="(v,i) in item.archivesBasicMultiselectList" :key="i"
                                       :label="v.archivesBasicValue"
                                       :value="v.archivesBasicValue"></el-option>
                        </el-select>
                        <!--时间-->
                        <el-date-picker class="w100i"
                                        v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                        format="yyyy-MM-dd HH:mm:ss"
                                        type="datetime" clearable placeholder="请选择"
                                        v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyy-MM-dd HH:mm:ss' || item.archivesBasicTypeCode == 'date' && !item.dataFormat">
                        </el-date-picker>
                        <el-date-picker class="w100i"
                                        v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                        format="yyyy-MM-dd"
                                        type="date" clearable placeholder="请选择"
                                        v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyy-MM-dd'">
                        </el-date-picker>
                        <el-date-picker class="w100i"
                                        v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                        format="yyyyMMdd"
                                        type="date" clearable placeholder="请选择"
                                        v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyyMMdd'">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <el-descriptions title="档案附件"></el-descriptions>
                <el-table :data="fileList" size="small" class="table" v-if="fileList.length">
                    <el-table-column prop="fileName" label="题名" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div>{{scope.row.fileName}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="fileSize" label="大小（MB）">
                        <template slot-scope="scope">{{getFileSize(scope.row.fileSize)}}</template>
                    </el-table-column>
                    <el-table-column prop="fileFormat" label="文件类型">
                        <template slot-scope="scope">
                            <img :src="getFileIcon(scope.row.fileFormat)" class="fileIcon">
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" width="260" label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="preview(scope.row)"
                                       v-if="hasPermission(scope.row.archivesBasicDataId + '-queryPermissions-1') ||  hasPermission(scope.row.archivesDataId + '-queryPermissions-1')">预览
                            </el-button>
                            <el-button type="text" size="small"
                                       v-if="scope.row.fileFormat == 'pdf' && hasPermission(scope.row.archivesBasicDataId + '-printPermissions-1') || scope.row.fileFormat == 'pdf' && hasPermission(scope.row.archivesDataId + '-printPermissions-1')"
                                       @click="print(scope.row)">打印
                            </el-button>
                            <el-button type="text" size="small"
                                       v-if="hasPermission(scope.row.archivesBasicDataId + '-downloadPermissions-1') || hasPermission(scope.row.archivesDataId + '-downloadPermissions-1')"
                                       @click="download(scope.row)">下载
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="noAccessory" v-else>
                    <img src="@/assets/img/noData.png">
                    <div>暂无相关数据</div>
                </div>
            </div>
        </div>
        <div class="text_center p_t2">
            <el-button size="small" @click="handleClose()">关闭</el-button>
            <el-button size="small" type="primary" v-if="collectId && collectFlag && pageType != 'admin'" @click="cancelCollect()" v-noMoreClick>取消收藏</el-button>
            <el-button size="small" type="primary" v-if="collectId && !collectFlag && pageType != 'admin'" @click="collect()" v-noMoreClick>加入收藏</el-button>
        </div>
        <!--预览-->
        <Preview ref="preview"></Preview>
    </el-dialog>
</template>

<script>
    import Preview from "../../collect/preview";

    export default {
        components: {
            Preview
        },
        data() {
            return {
                id: '',
                archivesBasicDataId: '',
                archivesBasicName: '',
                archivesBasicDataType: '',
                visible: false,
                method: '',
                classifyList: [],
                loading: false,
                config: [],
                inputForm: {},
                fileList: [],
                warehousingState: '', // 档案状态
                // 收藏
                collectId: '',
                collectFlag: true,
                pageType: '',
            }
        },
        methods: {
            init(row, method, recordTypeId, idType, collectId, pageType) {
                this.id = ''
                this.pageType = pageType
                this.inputForm = {}
                this.config = []
                this.collectFlag = true
                this.archivesBasicDataId = recordTypeId
                this.fileList = []
                this.loading = true
                this.method = method
                if (row) {
                    if (idType == 'archivesDataId') {
                        this.id = row.archivesDataId
                    } else {
                        this.id = row.id
                    }
                }
                if(collectId) {
                    this.collectId = collectId
                } else {
                    this.collect = ''
                }
                this.getRecord(recordTypeId)
                this.visible = true
            },
            // 获取数据类型
            getRecord(id) {
                this.$axios(this.api.record.basicdataGetById + '/' + id).then(data => {
                    if (data.status) {
                        this.config = data.data.archivesBasicDataGroup
                        this.archivesBasicName = data.data.archivesBasicName
                        this.archivesBasicDataType = data.data.archivesBasicDataType
                        this.getInfo()
                    }
                })
            },
            // 查询详情数据
            getInfo() {
                this.loading = true
                this.$axios(this.api.record.getById, {
                    archivesBasicDataId: this.archivesBasicDataId,
                    id: this.id
                }, 'get').then((res) => {
                    if (res.status) {
                        this.loading = false
                        this.inputForm = res.data.archivesData
                        this.warehousingState = res.data.warehousingState
                        this.inputForm.archivesDataName = res.data.archivesDataName
                        this.inputForm.quanzongNumber = res.data.quanzongNumber
                        this.inputForm.quanzongName = res.data.quanzongName
                        this.inputForm.reference = res.data.reference
                        this.fileList = res.data.archivesFileList
                        for (let key in this.inputForm) {
                            this.config.map(item => {
                                if (item.code == key && item.archivesBasicMultiselectList.length && item.archivesBasicMultiselectList[0].selectBy == 1) {
                                    if (this.inputForm[key]) {
                                        this.inputForm[key] = this.inputForm[key].split(',')
                                    } else {
                                        this.inputForm[key] = []
                                    }
                                }
                            })
                        }
                    }
                })
            },
            // 打印
            print(item) {
                this.loading = true
                this.$axios(this.api.record.utilizePrint, {archivesFileId: item.id}, 'get').then(res => {
                    if (res.status) {
                        this.$print({
                            printable: res.data,
                            type: item.fileFormat,
                            header: null,
                            targetStyles: ['*'],
                            style: "@page {margin:0 10mm}"
                        })
                        this.loading = false
                        setTimeout(() => {
                            this.getInfo()
                            let FirefoxDiv = document.getElementById('printJS')
                            if(navigator.userAgent.indexOf("Firefox")>0){
                                FirefoxDiv.style.cssText='width:0;height:0;position:absolute;visibility:hidden;border:0';
                            }
                        }, 1000)
                    } else {
                        this.$message.error('打印失败');
                    }
                })
            },
            // 下载
            download(item) {
                this.$confirm(`确认下载所选数据？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$axios(this.api.record.downloadArchivesFile, {
                        archivesFileId: item.id,
                    }, 'get', 'blob').then(res => {
                        let blob = new Blob([res.data])
                        let link = document.createElement('a')
                        link.download = decodeURI(item.fileName)
                        link.href = window.URL.createObjectURL(blob)
                        link.click()
                        this.loading = false
                        setTimeout(() => {
                            this.getInfo()
                        }, 1000)
                    })
                })
            },
            // 预览
            preview(item) {
                this.loading = true
                this.$axios(this.api.record.utilizeQuery, {archivesFileId: item.id}, 'get').then(res => {
                    if (res.status) {
                        let data = JSON.parse(JSON.stringify(item))
                        data.accessPath = res.data
                        this.$refs.preview.init(data)
                        this.loading = false
                        setTimeout(() => {
                            this.getInfo()
                        }, 1000)
                    } else {
                        this.$message.error('预览失败');
                    }
                })
            },
            // 收藏
            collect() {
                this.$confirm(`确定加入收藏吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.record.archivespersonalfavoritesSave, [{
                        archivesBasicDataId: this.archivesBasicDataId,
                        archivesDataId: this.id,
                    }], 'post').then(data => {
                        if (data.status) {
                            this.$message.success('收藏成功')
                            this.collectFlag = true
                        } else {
                            this.$message.error('收藏失败')
                        }
                    })
                })
            },
            // 取消收藏
            cancelCollect() {
                this.$confirm(`确定取消收藏吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.record.archivespersonalfavoritesRemoveById, [this.collectId], 'post').then(data => {
                        if (data.status) {
                            this.$message.success('取消成功')
                            this.collectFlag = false
                        } else {
                            this.$message.error('取消失败')
                        }
                    })
                })
            },
            // 获取文件大小
            getFileSize(size) {
                let num = 1024.00;
                return (size / Math.pow(num, 2)).toFixed(2)
            },
            // 关闭
            handleClose() {
                this.visible = false
                this.$emit("refreshDataList")
            },
        },
    }
</script>

<style scoped>
    .rowItem {
        width: 97% !important;
    }
</style>
