<template>
    <el-dialog :close-on-click-modal="false" append-to-body :fullscreen="true" :visible.sync="previewFlag"
               v-loading="loading" v-if="previewFlag">
        <iframe class="iframeBox" id="frame" :src='fileUrl'></iframe>
    </el-dialog>
</template>

<script>
    import {Base64} from 'js-base64'
    export default {
        data() {
            return {
                loading: false,
                previewFlag: false,
                previewData: {},
                fileUrl: ''
            }
        },
        methods: {
            init(data) {
                this.loading = true
                this.previewData = data
                this.fileUrl = this.$pdfPreviewUrl() + 'onlinePreview?url=' + encodeURIComponent(Base64.encode(data.accessPath)).replaceAll("\\+", "%20")
                this.$nextTick(() => {
                    this.previewFlag = true
                    this.loading = false
                })
            },
        },
    }
</script>

<style scoped>
   .iframeBox {
       width: 100%;
       height: calc(100vh - 95px);
   }
</style>
